.Button_button__iMXR5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: transparent;
  min-width: 12rem
}

.Button_button__iMXR5:disabled {
  cursor: default;
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(211, 212, 213, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.Button_wide__04iKa {
  width: 100%
}

.Button_primary__O3Hst {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 159, 217, var(--tw-ring-opacity))
}

.Button_secondary__6d8n_ {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 127, 173, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 127, 173, var(--tw-ring-opacity))
}

.Button_secondary__6d8n_:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_brand__m5Loe {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.Button_brand__m5Loe:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_tertiary__9ZzAY {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 159, 217, var(--tw-ring-opacity))
}

.Button_tertiary__9ZzAY:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_dark__G6eWa {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

.Button_dark__G6eWa:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

