.button {
  @apply flex items-center justify-center px-4 py-4 font-bold ring-transparent ring-inset ring-4 text-white;
  min-width: 12rem;
}

.button:disabled {
  @apply text-gray-400 bg-gray-200 cursor-default ring-gray-200;
}

.wide {
  @apply w-full;
}

.primary {
  @apply bg-brand ring-brand;
}

.secondary {
  @apply text-white bg-blue-500 ring-blue-500 active:text-black-300;
}

.brand {
  @apply text-white bg-brand active:text-black-300;
}

.tertiary {
  @apply text-white bg-brand ring-brand active:text-black-300;
}

.dark {
  @apply bg-gray-800 ring-gray-800 text-white active:text-black-300;
}
